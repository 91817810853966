import * as API from "@/game-lol/apis/api.mjs";
import { updateLatestChampionsPatch } from "@/game-lol/utils/actions.mjs";
import {
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
} from "@/game-lol/utils/util.mjs";

export default async function fetchData() {
  const promises = [];

  const filters = getDefaultedFiltersForChampions(null, null);
  const urlParams = getSearchParamsForChampions(false, filters);
  promises.push(
    API.AGGREGATE.getChampionsStats({
      filters,
      urlParams,
    }).then((data) => {
      updateLatestChampionsPatch(data);
    }),
  );

  promises.push(
    API.PROBUILDS.getProMatchHistory({
      role: null,
      championId: null,
    }),
  );

  await Promise.all(promises);
}
